export type Language = {
  type: 'language';
  name: string;
  setting: string;
  snippet: string;
};

export const languageList: Array<Language> = [
  {
    name: 'Ada',
    setting: 'ada',
    type: 'language',
    snippet:
      'with Ada.Text_IO, Ada.Command_Line;\n \nprocedure Fib is\n \n   X: Positive := Positive\'Value(Ada.Command_Line.Argument(1));\n \n   function Fib(P: Positive) return Positive is\n   begin\n      if P <= 2 then\n         return 1;\n      else\n         return Fib(P-1) + Fib(P-2);\n      end if;\n   end Fib;\n \nbegin\n   Ada.Text_IO.Put("Fibonacci(" & Integer\'Image(X) & " ) = ");\n   Ada.Text_IO.Put_Line(Integer\'Image(Fib(X)));\nend Fib;',
  },
  {
    name: 'Batch',
    setting: 'batch',
    type: 'language',
    snippet:
      '@echo off & setlocal enabledelayedexpansion\nfor /l %%i in (1,1,100) do (\n  set /a m5=%%i %% 5\n  set /a m3=%%i %% 3\n  set s=\n  if !m5! equ 0 set s=!s!Fizz\n  if !m3! equ 0 set s=!s!Buzz\n  if "!s!"=="" set s=%%i\n  echo !s!\n)',
  },
  {
    name: 'C',
    setting: 'c',
    type: 'language',
    snippet:
      'long long fibonacci(long long a, long long b, int n) {\n    return (--n>0)?(fibonacci(b, a+b, n)):(a);',
  },
  {
    name: 'C#',
    setting: 'csharp',
    type: 'language',
    snippet:
      'public static ulong Fibonacci(uint n) {\n    return (n < 2)? n : Fibonacci(n - 1) + Fibonacci(n - 2);\n}',
  },
  {
    name: 'C++',
    setting: 'cpp',
    type: 'language',
    snippet:
      '#include <iostream>\n\nint main()\n{\n        unsigned int a = 1, b = 1;\n        unsigned int target = 48;\n        for(unsigned int n = 3; n <= target; ++n)\n        {\n                unsigned int fib = a + b;\n                std::cout << "F("<< n << ") = " << fib << std::endl;\n                a = b;\n                b = fib;\n        }\n\n        return 0;\n}',
  },
  {
    name: 'Clojure',
    setting: 'clojure',
    type: 'language',
    snippet:
      '(defn fibonacci [n]\n  (case n\n    0 0\n    1 1\n    (+ (fibonacci (- n 1))\n       (fibonacci (- n 2)))))',
  },
  {
    name: 'COBOL',
    setting: 'cobol',
    type: 'language',
    snippet:
      '       >>SOURCE FREE\nIDENTIFICATION DIVISION.\nPROGRAM-ID. fibonacci-main.\n\nDATA DIVISION.\nWORKING-STORAGE SECTION.\n01  num                                 PIC 9(6) COMP.\n01  fib-num                             PIC 9(6) COMP.\n\nPROCEDURE DIVISION.\n    ACCEPT num\n    CALL "fibonacci" USING CONTENT num RETURNING fib-num\n    DISPLAY fib-num\n    .\nEND PROGRAM fibonacci-main.\n\nIDENTIFICATION DIVISION.\nPROGRAM-ID. fibonacci RECURSIVE.\n\nDATA DIVISION.\nLOCAL-STORAGE SECTION.\n01  1-before                            PIC 9(6) COMP.\n01  2-before                            PIC 9(6) COMP.\n\nLINKAGE SECTION.\n01  num                                 PIC 9(6) COMP.\n\n01  fib-num                             PIC 9(6) COMP BASED.\n\nPROCEDURE DIVISION USING num RETURNING fib-num.\n    ALLOCATE fib-num\n    EVALUATE num\n        WHEN 0\n            MOVE 0 TO fib-num\n        WHEN 1\n            MOVE 1 TO fib-num\n        WHEN OTHER\n            SUBTRACT 1 FROM num\n            CALL "fibonacci" USING CONTENT num RETURNING 1-before\n            SUBTRACT 1 FROM num\n            CALL "fibonacci" USING CONTENT num RETURNING 2-before\n            ADD 1-before TO 2-before GIVING fib-num\n    END-EVALUATE\n    .\nEND PROGRAM fibonacci.',
  },
  {
    name: 'CoffeeScript',
    setting: 'coffee',
    type: 'language',
    snippet:
      'fibonacci = (n) ->\n  if n < 2 then n else fibonacci(n-1) + fibonacci(n-2)',
  },
  {
    name: 'Common Lisp',
    setting: 'commonlisp',
    type: 'language',
    snippet:
      '(defun fibonacci (n)\n  (if (< n 2)\n      n\n     (+ (fibonacci (- n 2)) (fibonacci (- n 1)))))',
  },
  {
    name: 'Crystal',
    setting: 'crystal',
    type: 'language',
    snippet:
      'def fibonacci(n)\n  return n if n <= 1\n\n  return fibonacci(n - 1) + fibonacci(n - 2)\nend',
  },
  {
    name: 'CSS',
    setting: 'css',
    type: 'language',
    snippet: '.centered {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n}',
  },
  {
    name: 'Cucumber (Gherkin)',
    setting: 'gherkin',
    type: 'language',
    snippet:
      'Feature: Guess the word\n\n  # The first example has two steps\n  Scenario: Maker starts a game\n    When the Maker starts a game\n    Then the Maker waits for a Breaker to join\n\n  # The second example has three steps\n  Scenario: Breaker joins a game\n    Given the Maker has started a game with the word "silky"\n    When the Breaker joins the Maker\'s game\n    Then the Breaker must guess a word with 5 characters',
  },
  {
    name: 'D',
    setting: 'dscript',
    type: 'language',
    snippet:
      'import std.bigint, std.math;\n\nBigInt fibonacci(in ulong n)\nin {\n    assert(n > 0, "fibonacci(n): n must be > 0.");\n} body {\n    if (n <= 2)\n        return 1.BigInt;\n    BigInt F = 1;\n    BigInt L = 1;\n    int sign = -1;\n    immutable uint n2 = cast(uint)n.log2.floor;\n    auto mask = 2.BigInt ^^ (n2 - 1);\n    foreach (immutable i; 1 .. n2) {\n        auto temp = F ^^ 2;\n        F = (F + L) / 2;\n        F = 2 * F ^^ 2 - 3 * temp - 2 * sign;\n        L = 5 * temp + 2 * sign;\n        sign = 1;\n        if (n & mask) {\n            temp = F;\n            F = (F + L) / 2;\n            L = F + 2 * temp;\n            sign = -1;\n        }\n        mask /= 2;\n    }\n    if ((n & mask) == 0) {\n        F *= L;\n    } else {\n        F = (F + L) / 2;\n        F = F * L - sign;\n    }\n    return F;\n}',
  },
  {
    name: 'Dart',
    setting: 'dart',
    type: 'language',
    snippet:
      'int fibonacci(int n) {\n  if (n==0 || n==1) {\n    return n;\n  }\n  var prev=1;\n  var current=1;\n  for (var i=2; i<n; i++) {\n    var next = prev + current;\n    prev = current;\n    current = next;\n  }\n  return current;\n}',
  },
  {
    name: 'Diff',
    setting: 'diff',
    type: 'language',
    snippet:
      '$ diff -u file1.txt file2.txt\n--- file1.txt        2013-07-06 17:44:59.180000000 +0200\n+++ file2.txt        2013-07-06 17:39:53.433000000 +0200\n@@ -1,5 +1,5 @@\n this is the original text\n line2\n-line3\n line4\n happy hacking !\n+GNU is not UNIX',
  },
  {
    name: 'Django',
    setting: 'django',
    type: 'language',
    snippet:
      '{% extends "base_generic.html" %}\n\n{% block title %}{{ section.title }}{% endblock %}\n\n{% block content %}\n<h1>{{ section.title }}</h1>\n\n{% for story in story_list %}\n<h2>\n  <a href="{{ story.get_absolute_url }}">\n    {{ story.headline|upper }}\n  </a>\n</h2>\n<p>{{ story.tease|truncatewords:"100" }}</p>\n{% endfor %}\n{% endblock %}',
  },
  {
    name: 'Dockerfile',
    setting: 'dockerfile',
    type: 'language',
    snippet:
      'FROM ruby:3.0.1\n\nRUN bundle config --global frozen 1\n\nWORKDIR /usr/src/app\n\nCOPY Gemfile Gemfile.lock ./\n\nRUN bundle install\n\nCOPY app.rb .\n\nCMD [ "ruby", "app.rb" ]',
  },
  {
    name: 'Elixir',
    setting: 'elixir',
    type: 'language',
    snippet:
      'defmodule Fibonacci do\n    def fib(0), do: 0\n    def fib(1), do: 1\n    def fib(n), do: fib(0, 1, n-2)\n\n    def fib(_, prv, -1), do: prv\n    def fib(prvprv, prv, n) do\n        next = prv + prvprv\n        fib(prv, next, n-1)\n    end\nend',
  },
  {
    name: 'Elm',
    setting: 'elm',
    type: 'language',
    snippet:
      'fibonacci : Int -> Int\nfibonacci n = if n < 2 then\n        n\n    else\n        fibonacci(n - 2) + fibonacci(n - 1)',
  },
  {
    name: 'Erlang',
    setting: 'erlang',
    type: 'language',
    snippet:
      '-module(fib).\n-export([fib/1]).\n\nfib(0) -> 0;\nfib(1) -> 1;\nfib(N) -> fib(N-1) + fib(N-2).',
  },
  {
    name: 'F#',
    setting: 'fsharp',
    type: 'language',
    snippet:
      'let fibonacci n : bigint =\n  let rec f a b n =\n    match n with\n    | 0 -> a\n    | 1 -> b\n    | n -> (f b (a + b) (n - 1))\n  f (bigint 0) (bigint 1) n',
  },
  {
    name: 'Fortran',
    setting: 'fortran',
    type: 'language',
    snippet:
      '      FUNCTION IFIB(N)\n      IF (N.EQ.0) THEN\n        ITEMP0=0\n      ELSE IF (N.EQ.1) THEN\n        ITEMP0=1\n      ELSE IF (N.GT.1) THEN\n        ITEMP1=0\n        ITEMP0=1\n        DO 1 I=2,N\n          ITEMP2=ITEMP1\n          ITEMP1=ITEMP0\n          ITEMP0=ITEMP1+ITEMP2\n    1   CONTINUE\n      ELSE\n        ITEMP1=1\n        ITEMP0=0\n        DO 2 I=-1,N,-1\n          ITEMP2=ITEMP1\n          ITEMP1=ITEMP0\n          ITEMP0=ITEMP2-ITEMP1\n    2   CONTINUE\n      END IF\n      IFIB=ITEMP0\n      END',
  },
  {
    name: 'Git Commit',
    setting: 'git-commit',
    type: 'language',
    snippet:
      "created IDEA project\n\n# Please enter the commit message for your changes. Lines starting\n# with '#' will be ignored, and an empty message aborts the commit.\n# On branch master\n# Your branch is ahead of 'origin/master' by 1 commit.\n#   (use \"git push\" to publish your local commits)\n#\n# Changes to be committed:\n#       modified:   README.md\n#",
  },
  {
    name: 'Git Rebase',
    setting: 'git-rebase',
    type: 'language',
    snippet:
      'pick 07c5abd Introduce OpenPGP and teach basic usage\npick de9b1eb Fix PostChecker::Post#urls\npick fa20af3 git interactive rebase, squash, amend\n\n# Rebase 8db7e8b..fa20af3 onto 8db7e8b\n#\n# Commands:\n#  p, pick = use commit\n#  r, reword = use commit, but edit the commit message\n#  e, edit = use commit, but stop for amending\n#  s, squash = use commit, but meld into previous commit\n#  f, fixup = like "squash", but discard this commit\'s log message\n#  x, exec = run command (the rest of the line) using shell\n#\n# These lines can be re-ordered; they are executed from top to bottom.\n#\n# If you remove a line here THAT COMMIT WILL BE LOST.\n#\n# However, if you remove everything, the rebase will be aborted.\n#\n# Note that empty commits are commented out',
  },
  {
    name: 'Go',
    setting: 'go',
    type: 'language',
    snippet:
      'func fibonacci(a int) int {\n  if a < 2 {\n    return a\n  }\n  return fibonacci(a - 1) + fibonacci(a - 2)\n}',
  },
  {
    name: 'GraphQL',
    setting: 'graphql',
    type: 'language',
    snippet:
      'query HeroNameAndFriends {\n  hero {\n    name\n    friends {\n      name\n    }\n  }\n}',
  },
  {
    name: 'Groovy',
    setting: 'groovy',
    type: 'language',
    snippet:
      'def fibonacci\nfibonacci = {\n    it == 0   ? 0\n    : it == 1 ? 1\n    : it > 1  ? fibonacci(it-1) + fibonacci(it-2)\n    /*it < 0*/: fibonacci(it+2) - fibonacci(it+1)\n\n}',
  },
  {
    name: 'Handlebars',
    setting: 'handlebars',
    type: 'language',
    snippet:
      '<ul class="people_list">\n  {{#each people}}\n    <li>{{this}}</li>\n  {{/each}}\n</ul>',
  },
  {
    name: 'Haskell',
    setting: 'haskell',
    type: 'language',
    snippet:
      'fibonacci x =\n  if x < 1\n    then 0\n    else if x < 2\n           then 1\n           else fibonacci (x - 1) + fibonacci (x - 2)',
  },
  {
    name: 'HLSL',
    setting: 'hlsl',
    type: 'language',
    snippet:
      'Texture DiffuseTexture;\nsampler TextureSampler = sampler_state\n{\n    Texture = (DiffuseTexture);\n    MinFilter = Linear;\n    MagFilter = Linear;\n    MipFilter = Linear;\n    AddressU = Wrap;\n    AddressV = Wrap;\n};',
  },
  {
    name: 'HTML',
    setting: 'html',
    type: 'language',
    snippet:
      '<!DOCTYPE html>\n<html lang="en">\n<head>\n  <meta charset="utf-8">\n  <title>Source Code Shots</title>\n  <meta name="description" content="API to generate images from source code.">\n</head>\n<body>\n  <div id="root"></div>\n</body>\n</html>',
  },
  {
    name: 'Ignore',
    setting: 'ignore',
    type: 'language',
    snippet: '# ignore file\n\n.DS_STORE\n*.log',
  },
  {
    name: 'INI',
    setting: 'cfg',
    type: 'language',
    snippet:
      '; last modified 1 April 2001 by John Doe\n[owner]\nname=John Doe\norganization=Acme Widgets Inc.\n\n[database]\n; use IP address in case network name resolution is not working\nserver=192.0.2.62\nport=143\nfile="payroll.dat"',
  },
  {
    name: 'Java',
    setting: 'java',
    type: 'language',
    snippet:
      'public static long fibonacci(final int n)\n{\n return (n < 2) ? n : fibonacci(n - 1) + fibonacci(n - 2);\n}',
  },
  {
    name: 'JavaScript',
    setting: 'js',
    type: 'language',
    snippet:
      'function fibonacci(num) {\n  if (num <= 1) {\n    return num;\n  }\n\n  return fibonacci(num - 1) + fibonacci(num - 2);\n}',
  },
  {
    name: 'JSON',
    setting: 'json',
    type: 'language',
    snippet:
      '{\n  "glossary": {\n    "title": "example glossary",\n    "GlossDiv": {\n      "title": "S",\n      "GlossList": {\n        "GlossEntry": {\n          "ID": "SGML",\n          "SortAs": "SGML",\n          "GlossTerm": "Standard Generalized Markup Language",\n          "Acronym": "SGML",\n          "Abbrev": "ISO 8879:1986",\n          "GlossDef": {\n            "para": "A meta-markup language, used to create markup languages such as DocBook.",\n            "GlossSeeAlso": [\n              "GML",\n              "XML"\n            ]\n          },\n          "GlossSee": "markup"\n        }\n      }\n    }\n  }\n}',
  },
  {
    name: 'JSON5',
    setting: 'jsonwithcomments',
    type: 'language',
    snippet:
      "{\n  // comments\n  unquoted: 'and you can quote me on that',\n  singleQuotes: 'I can use \"double quotes\" here',\n  lineBreaks: \"Look, Mom! No \\n's!\",\n  hexadecimal: 0xdecaf,\n  leadingDecimalPoint: .8675309, andTrailing: 8675309.,\n  positiveSign: +1,\n  trailingComma: 'in objects', andIn: ['arrays',],\n  \"backwardsCompatible\": \"with JSON\",\n}",
  },
  {
    name: 'JSX',
    setting: 'jsx',
    type: 'language',
    snippet:
      'function Fibonacci() {\n  function fibonacci(n) {\n    if (n < 2) return n\n    return fibonacci(n - 1) + fibonacci(n - 2);\n  }\n  return <div>{fibonacci(10)}</div>\n}',
  },
  {
    name: 'Julia',
    setting: 'julia',
    type: 'language',
    snippet: 'fib(n) = n < 2 ? n : fib(n-1) + fib(n-2)',
  },
  {
    name: 'Kotlin',
    setting: 'kts',
    type: 'language',
    snippet:
      'enum class Fibonacci {\n    ITERATIVE {\n        override fun invoke(n: Long) = if (n < 2) {\n            n\n        } else {\n            var n1 = 0L\n            var n2 = 1L\n            var i = n\n            do {\n                val sum = n1 + n2\n                n1 = n2\n                n2 = sum\n            } while (i-- > 1)\n            n1\n        }\n    },\n    RECURSIVE {\n        override fun invoke(n: Long): Long = if (n < 2) n else this(n - 1) + this(n - 2)\n    };\n\n    abstract operator fun invoke(n: Long): Long\n}',
  },
  {
    name: 'LaTex',
    setting: 'latex',
    type: 'language',
    snippet:
      '\\documentclass[12pt,twoside,a4paper]{article}\n\\begin{document}\na small \\LaTeX document\n\\end{document}',
  },
  {
    name: 'Less',
    setting: 'less',
    type: 'language',
    snippet:
      '// Variables\n@link-color:        #428bca; // sea blue\n@link-color-hover:  darken(@link-color, 10%);\n\n// Usage\na,\n.link {\n  color: @link-color;\n}\na:hover {\n  color: @link-color-hover;\n}\n.widget {\n  color: #fff;\n  background: @link-color;\n}',
  },
  {
    name: 'Log',
    setting: 'log',
    type: 'language',
    snippet:
      '[30/Sep/2020:02:45:52 +0000] "POST /app/profile HTTP 1.1" 200 239 "" "Java/1.8.0_111" 25\n[30/Sep/2020:02:45:56 +0000] "POST /app/timeline HTTP 1.1" 200 153 "" "Java/1.8.0_111" 9\n[30/Sep/2020:02:45:57 +0000] "POST /app/friends HTTP 1.1" 200 217 "" "Java/1.8.0_111" 131\n[30/Sep/2020:02:45:58 +0000] "POST /app/album HTTP 1.1" 200 5113 "" "Java/1.8.0_101" 365\n[30/Sep/2020:02:45:58 +0000] "POST /app/timeline HTTP 1.1" 200 312 "" "Java/1.8.0_111" 15\n[30/Sep/2020:02:45:59 +0000] "POST /app/home HTTP 1.1" 200 168 "" "Java/1.8.0_91" 551\n[30/Sep/2020:02:45:59 +0000] "POST /app/home HTTP 1.1" 200 158 "" "Java/1.8.0_91" 707\n[30/Sep/2020:02:46:00 +0000] "POST /app/photo HTTP 1.1" 200 136 "" "Java/1.8.0_91" 13\n[30/Sep/2020:02:46:01 +0000] "POST /app/album HTTP 1.1" 200 3902 "" "Java/1.8.0_111" 323\n[30/Sep/2020:02:46:02 +0000] "POST /app/profile HTTP 1.1" 200 165 "" "Java/1.8.0_91" 986',
  },
  {
    name: 'Lua',
    setting: 'lua',
    type: 'language',
    snippet:
      'function fibonacci(n)\n  return n < 2 and n or fibonacci(n - 1) + fibonacci(n - 2)\nend',
  },
  {
    name: 'Makefile',
    setting: 'makefile',
    type: 'language',
    snippet:
      '  # the compiler: gcc for C program, define as g++ for C++\n  CC = gcc\n\n  # compiler flags:\n  #  -g    adds debugging information to the executable file\n  #  -Wall turns on most, but not all, compiler warnings\n  CFLAGS  = -g -Wall\n\n  # the build target executable:\n  TARGET = myprog\n\n  all: $(TARGET)\n\n  $(TARGET): $(TARGET).c\n  \t$(CC) $(CFLAGS) -o $(TARGET) $(TARGET).c\n\n  clean:\n  \t$(RM) $(TARGET)',
  },
  {
    name: 'Markdown',
    setting: 'markdown',
    type: 'language',
    snippet:
      '# Here’s some Markdown\n> It is _really_ **Markdown**\n\n<p>Here’s some HTML inside Markdown</p>\n<script>\n  function(js) {\n    return this.is(js.inHTML.inMarkdown);\n  }\n</script>\n<style>\n  .and-css-too:not(.too#shabby) {\n    color: #ff0000;\n  }\n</style>',
  },
  {
    name: 'Mathematica',
    setting: 'mathematica',
    type: 'language',
    snippet:
      'fibonacci[0] = 0\nfibonacci[1] = 1\nfibonacci[n_Integer] := fibonacci[n - 1] + fibonacci[n - 2]',
  },
  {
    name: 'N-Triples',
    setting: 'ntriples',
    type: 'language',
    snippet:
      ' <http://www.w3.org/2001/sw/RDFCore/ntriples/> <http://www.w3.org/1999/02/22-rdf-syntax-ns#type> ↵\n    <http://xmlns.com/foaf/0.1/Document> .\n <http://www.w3.org/2001/sw/RDFCore/ntriples/> <http://purl.org/dc/terms/title> "N-Triples"@en-US .\n <http://www.w3.org/2001/sw/RDFCore/ntriples/> <http://xmlns.com/foaf/0.1/maker> _:art .\n <http://www.w3.org/2001/sw/RDFCore/ntriples/> <http://xmlns.com/foaf/0.1/maker> _:dave .\n _:art <http://www.w3.org/1999/02/22-rdf-syntax-ns#type> <http://xmlns.com/foaf/0.1/Person> .\n _:art <http://xmlns.com/foaf/0.1/name> "Art Barstow".\n _:dave <http://www.w3.org/1999/02/22-rdf-syntax-ns#type> <http://xmlns.com/foaf/0.1/Person> .\n _:dave <http://xmlns.com/foaf/0.1/name> "Dave Beckett".',
  },
  {
    name: 'NGINX Conf',
    setting: 'nginx',
    type: 'language',
    snippet:
      'http {\n    server {\n        listen       80;\n        server_name  sourcecodeshots.com;\n\n        location / {\n            proxy_pass   http://127.0.0.1:3000;\n        }\n    }\n}',
  },
  {
    name: 'Nim',
    setting: 'nim',
    type: 'language',
    snippet:
      'proc Fibonacci(n: int): int64 =\n  if n <= 2:\n    result = 1\n  else:\n    result = Fibonacci(n - 1) + Fibonacci(n - 2)',
  },
  {
    name: 'Objective-C',
    setting: 'objective-c',
    type: 'language',
    snippet:
      '-(long)fibonacci:(int)position\n{\n    long result = 0;\n    if (position < 2) {\n        result = position;\n    } else {\n        result = [self fibonacci:(position -1)] + [self fibonacci:(position -2)];\n    }\n    return result;',
  },
  {
    name: 'Objective-C++',
    setting: 'objective-cpp',
    type: 'language',
    snippet:
      'bool EventHandler::needsKeyboardEventDisambiguationQuirks() const\n{\n    Document* document = m_frame->document();\n\n    // RSS view needs arrow key keypress events.\n    if (applicationIsSafari() && (document->url().protocolIs("feed") || document->url().protocolIs("feeds")))\n        return true;\n    Settings* settings = m_frame->settings();\n    if (!settings)\n        return false;\n\n#if ENABLE(DASHBOARD_SUPPORT)\n    if (settings->usesDashboardBackwardCompatibilityMode())\n        return true;\n#endif\n\n    if (settings->needsKeyboardEventDisambiguationQuirks())\n        return true;\n\n    return false;\n}',
  },
  {
    name: 'OCaml',
    setting: 'ocaml',
    type: 'language',
    snippet:
      'let rec fibonacci = function\n    0 -> 0\n  | 1 -> 1\n  | n -> if n > 0 then fibonacci (n-1) + fibonacci (n-2)\n         else fibonacci (n+2) - fibonacci (n+1)',
  },
  {
    name: 'Octave',
    setting: 'octave',
    type: 'language',
    snippet:
      'function fibonacci = recfibonacci(n)\n  if ( n < 2 )\n    fibonacci = n;\n  else\n    fibonacci = recfibonacci(n-1) + recfibonacci(n-2);\n  endif\nendfunction',
  },
  {
    name: 'Pascal',
    setting: 'pascal',
    type: 'language',
    snippet:
      'function fibonacci(n: integer): integer;\n begin\n  if (n = 0) or (n = 1)\n   then\n    fibonacci := n\n   else\n    fibonacci := fibonacci(n-1) + fibonacci(n-2)\n end;',
  },
  {
    name: 'Perl',
    setting: 'perl',
    type: 'language',
    snippet:
      'sub fibonacci {\n    my $n = shift;\n    $n < 2 ? $n : fibonacci($n - 1) + fibonacci($n - 2);\n}',
  },
  {
    name: 'PHP',
    setting: 'php',
    type: 'language',
    snippet:
      'function fibonacci($n) {\n    return $n < 2 ? $n : fibonacci($n-1) + fibonacci($n-2);\n}',
  },
  {
    name: 'Plain Text',
    setting: 'text',
    type: 'language',
    snippet: 'Just some plain text\n\nNo formatting here.',
  },
  {
    name: 'Powershell',
    setting: 'powershell',
    type: 'language',
    snippet:
      'function fibonacci($n) {\n    switch ($n) {\n        0            { return 0 }\n        1            { return 1 }\n        { $_ -lt 0 } { return [Math]::Pow(-1, -$n + 1) * (fibonacci (-$n)) }\n        default      { return (fibonacci ($n - 1)) + (fibonacci ($n - 2)) }\n    }\n}',
  },
  {
    name: 'Python',
    setting: 'python',
    type: 'language',
    snippet:
      'def fibonacci(n):\n    if n < 2:\n        return n\n    else:\n        return fibonacci(n-1) + fibonacci(n-2)',
  },
  {
    name: 'R',
    setting: 'r',
    type: 'language',
    snippet:
      'fib=function(n,x=c(0,1)) {\n   if (abs(n)>1) for (i in seq(abs(n)-1)) x=c(x[2],sum(x))\n   if (n<0) return(x[2]*(-1)^(abs(n)-1)) else if (n) return(x[2]) else return(0)\n}\n\nsapply(seq(-31,31),fib)',
  },
  {
    name: 'Racket',
    setting: 'racket',
    type: 'language',
    snippet:
      '(define (fibonacci n (a 0) (b 1))\n  (if (< n 2)\n      1\n      (+ a (fibonacci (- n 1) b (+ a b)))))',
  },
  {
    name: 'Raku (Perl 6)',
    setting: 'perl6',
    type: 'language',
    snippet:
      'proto fibonacci (Int $n --> Int) {*}\nmulti fibonacci (0)  { 0 }\nmulti fibonacci (1)  { 1 }\nmulti fibonacci ($n) { fibonacci($n - 1) + fibonacci($n - 2) }',
  },
  {
    name: 'Reason',
    setting: 'reason',
    type: 'language',
    snippet:
      'let rec fibonacci =\n  fun\n  | 0 => 0\n  | 1 => 1\n  | n =>\n    if (n > 0) {\n      fibonacci(n - 1) + fibonacci(n - 2);\n    } else {\n      fibonacci(n + 2) - fibonacci(n + 1);\n    };',
  },
  {
    name: 'Reason Lisp',
    setting: 'reason_lisp',
    type: 'language',
    snippet:
      '(defn fibonacci [n]\n  (if (< n 2)\n    n\n    (+ (fibonacci (- n 1)) (fibonacci (- n 2)))))',
  },
  {
    name: 'ReScript',
    setting: 'rescript',
    type: 'language',
    snippet:
      'let rec fibonacci = n =>\n  switch n {\n  | 0 => 0\n  | 1 => 1\n  | n =>\n    if n > 0 {\n      fibonacci(n - 1) + fibonacci(n - 2)\n    } else {\n      fibonacci(n + 2) - fibonacci(n + 1)\n    }\n  }',
  },
  {
    name: 'RISC-V',
    setting: 'riscv',
    type: 'language',
    snippet:
      '.text;\n.global _start;\n\n#### BEGIN _start\n_start:\n\tli s0, 0x1040;\t\t# GPIO address\n\tadd s1, zero, zero;\t# GPIO status 1\n\taddi s1, zero, 3;\t# GPIO status 2\n\t#ori s1, zero, 0x7;\t# This is if you want strong driver\ninfinite:\n\tli t0, 0x6000;\t# Counter\n\tli t1, 0x1;\n\tfor_counter_1:\n\t\tsub t0, t0, t1;\n\t\tbne t0, zero, for_counter_1;\n\txori s1, s1, 0x3;\t# Toogle GPIO status\n\txori s2, s2, 0x3;\t# Toogle GPIO status\n\tandi s1, s1, 0x7;\n\tandi s2, s2, 0x7;\n\tsw s1, 0(s0);\t\t# Write to GPIO 1\n\tsw s1, 4(s0);\t\t# Write to GPIO 2\n\tsw s1, 8(s0);\t\t# Write to GPIO 3\n\tsw s2, 12(s0);\t\t# Write to GPIO 4\n\tsw s2, 16(s0);\t\t# Write to GPIO 5\n\tsw s2, 20(s0);\t\t# Write to GPIO 6\n\t#sw s1, 24(s0);\t\t# Write to GPIO 7\n\t#sw s1, 28(s0);\t\t# Write to GPIO 8\n\tjal zero, infinite;\n\n.data\nsocket_call:   .long 281\nconnect_call:  .long 283\n\n/* all addresses are network byte-order (big-endian) */\nserver_addr:            .long 0x0100007f ; localhost\nserver_port:            .hword 0x0b1a',
  },
  {
    name: 'Ruby',
    setting: 'ruby',
    type: 'language',
    snippet:
      'def fibonacci(n, sequence=[1])\n  return sequence.last if n == 0\n\n  current_number, last_number = sequence.last(2)\n  sequence << current_number + (last_number or 0)\n\n  fibonacci(n-1, sequence)\nend',
  },
  {
    name: 'Rust',
    setting: 'rust',
    type: 'language',
    snippet:
      'fn fibonacci(mut prev: usize, mut curr: usize) {\n    mem::swap(&mut prev, &mut curr);\n    if let Some(n) = curr.checked_add(prev) {\n        println!("{}", n);\n        fibonacci(prev, n);\n    }\n}',
  },
  {
    name: 'Sass',
    setting: 'sass',
    type: 'language',
    snippet:
      '$font-stack:    Helvetica, sans-serif;\n$primary-color: #333;\n\nbody {\n  font: 100% $font-stack;\n  color: $primary-color;\n}',
  },
  {
    name: 'Scala',
    setting: 'scala',
    type: 'language',
    snippet:
      'def fibonacci(i: Int): Int = i match {\n  case 0 => 0\n  case 1 => 1\n  case _ => fibonacci(i - 1) + fibonacci(i - 2)\n}',
  },
  {
    name: 'Scheme',
    setting: 'scheme',
    type: 'language',
    snippet:
      '(define (fibonacci n)\n  (if (< n 2)\n      n\n      (+ (fibonacci (- n 1))\n         (fibonacci (- n 2)))))',
  },
  {
    name: 'SCSS',
    setting: 'scss',
    type: 'language',
    snippet:
      '@mixin absolute-center() {\n  position:absolute;\n  left:50%;\n  top:50%;\n  transform:translate(-50%,-50%);\n}\n.element-1 {\n  @include absolute-center();\n}\n.element-2 {\n  @include absolute-center();\n  color:blue;\n}',
  },
  {
    name: 'ShaderLab',
    setting: 'shaderlab',
    type: 'language',
    snippet:
      '  Shader "Example/Diffuse Simple" {\n    SubShader {\n      Tags { "RenderType" = "Opaque" }\n      CGPROGRAM\n      #pragma surface surf Lambert\n      struct Input {\n          float4 color : COLOR;\n      };\n      void surf (Input IN, inout SurfaceOutput o) {\n          o.Albedo = 1;\n      }\n      ENDCG\n    }\n    Fallback "Diffuse"\n  }',
  },
  {
    name: 'Shell',
    setting: 'shellscript',
    type: 'language',
    snippet:
      '#!/bin/bash\n\na=0\nb=1\nmax=$1\n\nfor (( n=1; "$n" <= "$max"; $((n++)) ))\ndo\n  a=$(($a + $b))\n  echo "F($n): $a"\n  b=$(($a - $b))\ndone',
  },
  {
    name: 'SmallTalk',
    setting: 'smalltalk',
    type: 'language',
    snippet:
      '|fibonacci|\nfibonacci := [ :i |\n   |ac t|\n   ac := Array new: 2.\n   ac at: 1 put: 0 ; at: 2 put: 1.\n   ( i < 2 )\n     ifTrue: [ ac at: (i+1) ]\n     ifFalse: [\n        2 to: i do: [ :l |\n          t := (ac at: 2).\n          ac at: 2 put: ( (ac at: 1) + (ac at: 2) ).\n          ac at: 1 put: t\n        ].\n        ac at: 2.\n     ]\n].',
  },
  {
    name: 'SQL',
    setting: 'sql',
    type: 'language',
    snippet:
      'WITH RECURSIVE search_graph(id, link, data, depth) AS (\n    SELECT g.id, g.link, g.data, 1\n    FROM graph g\n  UNION ALL\n    SELECT g.id, g.link, g.data, sg.depth + 1\n    FROM graph g, search_graph sg\n    WHERE g.id = sg.link\n)\nSELECT * FROM search_graph;',
  },
  {
    name: 'Swift',
    setting: 'swift',
    type: 'language',
    snippet:
      'func fibonacci(n: Int) -> Int {\n    if n < 2 {\n        return n\n    } else {\n        return fibonacci(n-1) + fibonacci(n-2)\n    }\n}',
  },
  {
    name: 'TCL',
    setting: 'tcl',
    type: 'language',
    snippet:
      'proc fibonacci {n} {\n    if {$n < 2} then {expr {$n}} else {expr {[fibonacci [expr {$n-1}]]+[fibonacci [expr {$n-2}]]} }\n}',
  },
  {
    name: 'TOML',
    setting: 'toml',
    type: 'language',
    snippet:
      '# This is a TOML document.\n\ntitle = "TOML Example"\n\n[owner]\nname = "Tom Preston-Werner"\ndob = 1979-05-27T07:32:00-08:00 # First class dates\n\n[database]\nserver = "192.168.1.1"\nports = [ 8001, 8001, 8002 ]\nconnection_max = 5000\nenabled = true\n\n[servers]\n\n  # Indentation (tabs and/or spaces) is allowed but not required\n  [servers.alpha]\n  ip = "10.0.0.1"\n  dc = "eqdc10"\n\n  [servers.beta]\n  ip = "10.0.0.2"\n  dc = "eqdc10"\n\n[clients]\ndata = [ ["gamma", "delta"], [1, 2] ]\n\n# Line breaks are OK when inside arrays\nhosts = [\n  "alpha",\n  "omega"\n]',
  },
  {
    name: 'TSX',
    setting: 'tsx',
    type: 'language',
    snippet:
      'function Fibonaci(props: FibonacciProps) {\n  function fibonacci(n: number): number {\n    if (n < 2) return n\n    return fibonacci(n - 1) + fibonacci(n - 2);\n  }\n  return <div>{fibonacci(props.n)}</div>\n}',
  },
  {
    name: 'Twig',
    setting: 'twig',
    type: 'language',
    snippet:
      '<!DOCTYPE html>\n<html>\n    <head>\n        <title>My Webpage</title>\n    </head>\n    <body>\n        <ul id="navigation">\n        {% for item in navigation %}\n            <li><a href="{{ item.href }}">{{ item.caption }}</a></li>\n        {% endfor %}\n        </ul>\n\n        <h1>My Webpage</h1>\n        {{ a_variable }}\n    </body>\n</html>',
  },
  {
    name: 'TypeScript',
    setting: 'ts',
    type: 'language',
    snippet:
      'function fibonacci(num: number): number {\n  if (num <= 1) {\n    return num;\n  }\n\n  return fibonacci(num - 1) + fibonacci(num - 2);\n}',
  },
  {
    name: 'Verilog',
    setting: 'verilog',
    type: 'language',
    snippet:
      '//-----------------------------------------------------\n// Design Name : mux_using_assign\n// File Name   : mux_using_assign.v\n// Function    : 2:1 Mux using Assign\n// Coder       : Deepak Kumar Tala\n//-----------------------------------------------------\nmodule  mux_using_assign(\ndin_0      , // Mux first input\ndin_1      , // Mux Second input\nsel        , // Select input\nmux_out      // Mux output\n);\n//-----------Input Ports---------------\ninput din_0, din_1, sel ;\n//-----------Output Ports---------------\noutput mux_out;\n//------------Internal Variables--------\nwire  mux_out;\n//-------------Code Start-----------------\nassign mux_out = (sel) ? din_1 : din_0;\n\nendmodule //End Of Module mux',
  },
  {
    name: 'VHDL',
    setting: 'vhdl',
    type: 'language',
    snippet:
      '-- VHDL code for AND-OR-INVERT gate\n\nlibrary IEEE;\nuse IEEE.STD_LOGIC_1164.all;\n\nentity AOI is\nport (\n  A, B, C, D: in STD_LOGIC;\n  F : out STD_LOGIC\n);\nend AOI;\n\narchitecture V1 of AOI is\nbegin\n  F <= not ((A and B) or (C and D));\nend V1;\n\n-- end of VHDL code',
  },
  {
    name: 'Visual Basic',
    setting: 'visualbasic',
    type: 'language',
    snippet:
      'Function Seq(ByVal Term As Integer)\n        If Term < 2 Then Return Term\n        Return Seq(Term - 1) + Seq(Term - 2)\nEnd Function',
  },
  {
    name: 'Vue HTML',
    setting: 'vue',
    type: 'language',
    snippet:
      '<div id="app-3">\n  <span v-if="seen">Now you see me</span>\n</div>',
  },
  {
    name: 'XML',
    setting: 'xml',
    type: 'language',
    snippet:
      '<?xml version="1.0"?>\n<catalog>\n   <book id="bk101">\n      <author>Gambardella, Matthew</author>\n      <title>XML Developer\'s Guide</title>\n      <genre>Computer</genre>\n   </book>\n   <book id="bk102">\n      <author>Ralls, Kim</author>\n      <title>Midnight Rain</title>\n      <genre>Fantasy</genre>\n   </book>\n   <book id="bk103">\n      <author>Corets, Eva</author>\n      <title>Maeve Ascendant</title>\n      <genre>Fantasy</genre>\n   </book>\n</catalog>',
  },
  {
    name: 'XQuery',
    setting: 'xquery',
    type: 'language',
    snippet:
      'declare function local:fibonacci($n as xs:integer) as xs:integer {\n  if($n < 2)\n  then $n\n  else local:fibonacci($n - 1) + local:fibonacci($n - 2)\n};',
  },
  {
    name: 'Yaml',
    setting: 'yaml',
    type: 'language',
    snippet:
      '--- !clarkevans.com/^invoice\ninvoice: 34843\ndate   : 2001-01-23\nbill-to: &id001\n    given  : Chris\n    family : Dumars\n    address:\n        lines: |\n            458 Walkman Dr.\n            Suite #292\n        city    : Royal Oak\n        state   : MI\n        postal  : 48046\nship-to: *id001\nproduct:\n    - sku         : BL394D\n      quantity    : 4\n      description : Basketball\n      price       : 450.00\n    - sku         : BL4438H\n      quantity    : 1\n      description : Super Hoop\n      price       : 2392.00\ntax  : 251.42\ntotal: 4443.52\ncomments: >\n    Late afternoon is best.\n    Backup contact is Nancy\n    Billsmer @ 338-4338.',
  },
  {
    name: 'Zig',
    setting: 'zig',
    type: 'language',
    snippet:
      'const std = @import("std");\nconst assert = std.debug.assert;\n\nfn fibonacci(x: u32) u32 {\n    if (x <= 1) return x;\n    return fibonacci(x - 1) + fibonacci(x - 2);\n}\n\ntest "compile-time evaluation" {\n    var array: [fibonacci(6)]i32 = undefined;\n\n    comptime {\n        assert(array.len == 12345);\n    }\n}',
  },
];

export const languages: {[setting: string]: Language} = {};
for (const lang of languageList) {
  if (languages[lang.setting]) {
    throw new Error('oops, repeated language');
  }
  languages[lang.setting] = lang;
}

export type Theme = {
  type: 'theme';
  id: string;
  setting: string;
  label: string;
};

export const themes: {
  [id: string]: Theme;
} = {
  abyss: {
    type: 'theme',
    id: 'abyss-color-theme',
    setting: 'abyss',
    label: 'Abyss',
  },
  aylin: {
    type: 'theme',
    id: 'aylin',
    setting: 'aylin',
    label: 'Aylin',
  },
  'dark-plus': {
    type: 'theme',
    id: 'Default Dark+',
    setting: 'dark-plus',
    label: 'Dark+ (default dark)',
  },
  'light-plus': {
    type: 'theme',
    id: 'Default Light+',
    setting: 'light-plus',
    label: 'Light+ (default light)',
  },
  'github-dark': {
    type: 'theme',
    id: 'github-dark',
    setting: 'github-dark',
    label: 'GitHub Dark',
  },
  'github-light': {
    type: 'theme',
    id: 'github-light',
    setting: 'github-light',
    label: 'GitHub Light',
  },
  'visual-studio-dark': {
    type: 'theme',
    id: 'Visual Studio Dark',
    setting: 'visual-studio-dark',
    label: 'Dark (Visual Studio)',
  },
  'visual-studio-light': {
    type: 'theme',
    id: 'Visual Studio Light',
    setting: 'visual-studio-light',
    label: 'Light (Visual Studio)',
  },
  'high-contrast': {
    type: 'theme',
    id: 'Default High Contrast',
    setting: 'high-contrast',
    label: 'High Contrast',
  },
  'kimbie-dark': {
    type: 'theme',
    id: 'kimbie-dark-color-theme',
    setting: 'kimbie-dark',
    label: 'Kimbie Dark',
  },
  'dimmed-monokai': {
    type: 'theme',
    id: 'dimmed-monokai-color-theme',
    setting: 'dimmed-monokai',
    label: 'Monokai Dimmed',
  },
  monokai: {
    type: 'theme',
    id: 'monokai-color-theme',
    setting: 'monokai',
    label: 'Monokai',
  },
  'night-owl': {
    type: 'theme',
    id: 'night-owl',
    label: 'Night Owl',
    setting: 'night-owl',
  },
  'night-owl-no-italic': {
    type: 'theme',
    id: 'night-owl-no-italic',
    setting: 'night-owl-no-italic',
    label: 'Night Owl (No Italics)',
  },

  'night-owl-light': {
    type: 'theme',
    id: 'night-owl-light',
    setting: 'night-owl-light',
    label: 'Night Owl Light',
  },
  'night-owl-light-no-italic': {
    type: 'theme',
    id: 'night-owl-light-no-italic',
    setting: 'night-owl-light-no-italic',
    label: 'Night Owl Light (No Italics)',
  },
  quietlight: {
    type: 'theme',
    id: 'quietlight-color-theme',
    setting: 'quietlight',
    label: 'Quiet Light',
  },
  red: {
    type: 'theme',
    id: 'Red-color-theme',
    label: 'Red',
    setting: 'red',
  },
  'solarized-dark': {
    type: 'theme',
    id: 'solarized-dark-color-theme',
    label: 'Solarized Dark',
    setting: 'solarized-dark',
  },
  'solarized-light': {
    type: 'theme',
    id: 'solarized-light-color-theme',
    label: 'Solarized Light',
    setting: 'solarized-light',
  },
  'tomorrow-night-blue': {
    type: 'theme',
    id: 'tomorrow-night-blue-theme',
    label: 'Tomorrow Night Blue',
    setting: 'tomorrow-night-blue',
  },
};
