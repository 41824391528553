import React, {useEffect} from 'react';
import {
  Box,
  Heading,
  Text,
  Paragraph,
  Image,
  Table as GrommetTable,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from 'grommet';
import {Helmet} from 'react-helmet';
import {Link, RouteComponentProps} from 'react-router-dom';
import {languages, themes} from './settings';
// @ts-ignore
import Lightbox from 'react-awesome-lightbox';
// @ts-ignore
import styled from 'styled-components';

import 'react-awesome-lightbox/build/style.css';

import type {Language, Theme} from './settings';
import type {FunctionComponent} from 'react';

const Table = styled(GrommetTable)`
  @media (max-width: 800px) {
    table-layout: fixed;
    width: calc(100vw - 12px);
    overflow: scroll;
  }
`;

const Pre = styled.pre`
  display: block;
  overflow: scroll;
  overflow-x: auto;
  padding: 0.5em;
  color: rgb(51, 51, 51);
  background: rgb(248, 248, 248);
`;

const Code = styled.code`
  font-family: Hack, source-code-pro, Menlo, Monaco, Consolas, Courier New,
    monospace;
  font-size: 0.8em;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function P(props: any) {
  return <Paragraph style={{maxWidth: 64 * 10}} {...props} />;
}

function defaultThemeSrc(theme: Theme) {
  return `/image/default/js/${theme.setting}.png`;
}

function defaultLanguageSrc(lang: Language) {
  return `/image/default/${lang.setting}/dark-plus.png`;
}

function defaultSrc(option: Theme | Language) {
  if (option.type === 'theme') {
    return defaultThemeSrc(option);
  } else {
    return defaultLanguageSrc(option);
  }
}

function lightboxTitle(option: Theme | Language) {
  if (option.type === 'theme') {
    return `${option.label} Theme with JavaScript`;
  } else {
    return `${option.name} with the Dark+ (default dark) theme`;
  }
}

type LightboxOptionIdxMap = Map<LightboxOption, number>;
function getAllImages(): {
  imageUrls: {url: string; title: string}[];
  imageOptionIdxMap: LightboxOptionIdxMap;
} {
  const idxMap = new Map();
  const all = [];

  let idx = 0;
  for (const k of Object.keys(themes)) {
    const theme = themes[k];
    all.push({url: defaultSrc(theme), title: lightboxTitle(theme)});
    idxMap.set(theme, idx);
    idx++;
  }

  for (const k of Object.keys(languages)) {
    const language = languages[k];
    all.push({url: defaultSrc(language), title: lightboxTitle(language)});
    idxMap.set(language, idx);
    idx++;
  }

  return {
    imageUrls: all,
    imageOptionIdxMap: idxMap,
  };
}

type LightboxOption = Theme | Language;

function CodeBlock({
  children,
}: {
  children: (JSX.Element | string)[] | JSX.Element | string;
}) {
  return (
    <Text>
      <Pre>
        <Code>{children}</Code>
      </Pre>
    </Text>
  );
}

function HighlightCodeBlock({code}: {code: string}) {
  const [highlighted, setHighlighted] = React.useState<string | JSX.Element>(
    code,
  );
  const [preStyle, setPreStyle] = React.useState<React.CSSProperties>({});

  useEffect(() => {
    fetch('https://sourcecodeshots.com/api/token-info', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        code: code,
        settings: {
          language: 'js',
          theme: 'night-owl-light',
        },
      }),
    })
      .then((response) => response.json())
      .then((tokenInfo) => {
        const formatted = (
          <React.Fragment>
            {tokenInfo.tokens.map((lineTokens: any, idx: number) => {
              return (
                <React.Fragment key={idx}>
                  {lineTokens.map((token: any, tokenIdx: number) => {
                    const style: React.CSSProperties = {
                      color: token.foregroundColor,
                    };
                    if (token.isItalic) {
                      style.fontStyle = 'italic';
                    }
                    if (token.isBold) {
                      style.fontWeight = 'bold';
                    }
                    if (token.isUnderlined) {
                      style.textDecoration = 'underline';
                    }
                    return (
                      <span key={tokenIdx} style={style}>
                        {token.text}
                      </span>
                    );
                  })}
                  {idx === tokenInfo.tokens.length - 1 ? null : '\n'}
                </React.Fragment>
              );
            })}
          </React.Fragment>
        );
        setHighlighted(formatted);
        setPreStyle({
          backgroundColor: tokenInfo.backgroundColor,
          color: tokenInfo.foregroundColor,
        });
      });
  }, [code]);

  return (
    <Text>
      <Pre style={preStyle}>
        <Code>{highlighted}</Code>
      </Pre>
    </Text>
  );
}

function SettingsType() {
  return (
    <CodeBlock>
      {'{\n'}
      {'  '}code: string;{'\n'}
      {'  '}settings: {'{\n'}
      {'    '}language:{' '}
      <Link to="/docs/supported-languages">Supported Language</Link>,{'\n'}
      {'    '}theme: <Link to="/docs/supported-themes">Supported Theme</Link>,
      {'\n'}
      {'    '}tabWidth: integer (defaults to 2)
      {'\n'}
      {'  }\n'}
      {'}'}
    </CodeBlock>
  );
}

const settingsValue = `{
  code: "console.log(\"hello world\"),
  settings: {
    language: "js",
    theme: "dark-plus"
  }
}`;

const postExample = `async function fetchImage() {
  const response = await fetch('https://sourcecodeshots.com/api/image', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({
      code: 'console.log("hello world")',
      settings: {
        language: 'js',
        theme: 'dark-plus',
      },
    }),
  });
  return await response.blob();
}`;

const imageIdResponseBodyType = `{
  url: string;
}`;

const imageIdResponseBodyExample = `{
  url: "https://sourcecodeshots.com/image/yvnYTD8AWdzl2SRIBdwH.png"
}`;

const imageIdExample = `async function fetchPermanentUrl() {
  const response = await fetch(
    'https://sourcecodeshots.com/api/image/permalink',
    {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        code: 'console.log("hello world")',
        settings: {
          language: 'js',
          theme: 'dark-plus',
        },
      }),
    },
  );
  const json = await response.json();
  return json.url;
}`;

const tokenInfoResponseBodyType = `{
  backgroundColor: string;
  foregroundColor: string;
  windowTheme: 'light' | 'dark';
  tokens: Array<
    Array<{
      text: string;
      foregroundColor: string;
      backgroundColor: string;
      isItalic: boolean;
      isBold: boolean;
      isUnderlined: boolean;
    }>
  >
}`;

const tokenInfoResponseBodyExample = `{
  "backgroundColor": "#1E1E1E",
  "foregroundColor": "#D4D4D4",
  "backdropColor": "transparent",
  "windowTheme": "dark"
  "tokens": [
    [
      {
        "text": "console",
        "foregroundColor": "#4EC9B0",
        "backgroundColor": "#1E1E1E",
        "isItalic": false,
        "isBold": false,
        "isUnderlined": false
      },
      {
        "text": ".",
        "foregroundColor": "#D4D4D4",
        "backgroundColor": "#1E1E1E",
        "isItalic": false,
        "isBold": false,
        "isUnderlined": false
      },
      {
        "text": "log",
        "foregroundColor": "#DCDCAA",
        "backgroundColor": "#1E1E1E",
        "isItalic": false,
        "isBold": false,
        "isUnderlined": false
      },
      {
        "text": "(",
        "foregroundColor": "#D4D4D4",
        "backgroundColor": "#1E1E1E",
        "isItalic": false,
        "isBold": false,
        "isUnderlined": false
      },
      {
        "text": "\"hello world\"",
        "foregroundColor": "#CE9178",
        "backgroundColor": "#1E1E1E",
        "isItalic": false,
        "isBold": false,
        "isUnderlined": false
      },
      {
        "text": ")",
        "foregroundColor": "#D4D4D4",
        "backgroundColor": "#1E1E1E",
        "isItalic": false,
        "isBold": false,
        "isUnderlined": false
      }
    ]
  ]
}
`;

const hightlightingExample = `async function highlightCode(code: string) {
  const response = await fetch('https://sourcecodeshots.com/api/token-info', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({
      code: code,
      settings: {
        language: 'js',
        theme: 'dark-plus',
      },
    }),
  });
  const tokenInfo = await response.json();

  return (
    <pre
      style={{
        color: tokenInfo.foregroundColor,
        background: tokenInfo.backgroundColor,
      }}>
      <code>
        {tokenInfo.tokens.map((lineTokens, idx) => {
          return (
            <React.Fragment key={idx}>
              {lineTokens.map((token, tokenIdx) => {
                const style = {
                  color: token.foregroundColor,
                };
                if (token.isItalic) {
                  style.fontStyle = 'italic';
                }
                if (token.isBold) {
                  style.fontWeight = 'bold';
                }
                if (token.isUnderlined) {
                  style.textDecoration = 'underline';
                }
                return (
                  <span key={tokenIdx} style={style}>
                    {token.text}
                  </span>
                );
              })}
              {idx === tokenInfo.tokens.length - 1 ? null : \'\\n\'}
            </React.Fragment>
          );
        })}
      </code>
    </pre>
  );
}`;

const Docs: FunctionComponent<RouteComponentProps<{
  hash?: string | undefined;
}>> = (props) => {
  const hash = props.match.params.hash;
  const supportedLanguagesRef = React.useRef<HTMLHeadingElement | null>(null);
  const supportedThemesRef = React.useRef<HTMLHeadingElement | null>(null);
  const highlightCodeRef = React.useRef<HTMLHeadingElement | null>(null);

  React.useEffect(() => {
    switch (hash) {
      case null:
      case undefined:
        break;
      case 'supported-languages':
        if (supportedLanguagesRef.current) {
          supportedLanguagesRef.current.scrollIntoView(true);
        }
        break;
      case 'supported-themes':
        if (supportedThemesRef.current) {
          supportedThemesRef.current.scrollIntoView(true);
        }
        break;
      case 'highlight':
        if (highlightCodeRef.current) {
          highlightCodeRef.current.scrollIntoView(true);
        }
        break;
      default:
        console.warn('unknown hash', hash);
    }
  }, [hash]);

  const {imageOptionIdxMap, imageUrls} = React.useMemo(getAllImages, []);
  const [
    lightboxOption,
    setLightboxOption,
  ] = React.useState<LightboxOption | null>(null);
  return (
    <>
      <Helmet>
        <title>Source Code Shots - Documentation</title>
      </Helmet>
      <Box align="center" pad="small">
        <Box width="large">
          <Box width={{max: `${64 * 10}px`}}>
            <Heading level={2}>Documentation</Heading>
            <P>
              Source Code Shots is free for personal projects with reasonable
              usage. Please{' '}
              <a href="mailto:dwwoelfel@gmail.com?subject=Source Code Shots for professional use&body=I would like to use source code shots at my company">
                contact me
              </a>{' '}
              before sending large volumes of requests or if you would like to
              use Source Code Shots at your company.
            </P>
            <Heading level={3}>Simple Image URL</Heading>
            <P>
              If you have a short snippet of code, you can create a simple url
              that will return an image. Only use this method if the resulting
              URL is less than 2083 characters, otherwise some browsers may
              reject your request. If it is over 4kb, the server will also
              reject the request.
            </P>
            <P>
              This method is ideal for creating dynamic urls to put in the{' '}
              <Code>src</Code> prop of an
              <Code>img</Code> tag.
            </P>
            <Heading level={5}>Path</Heading>
            <Text>
              <Code>/api/image</Code>
            </Text>
            <Heading level={5}>Query Parameters</Heading>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableCell scope="col" border="bottom">
                    Param
                  </TableCell>
                  <TableCell scope="col" border="bottom">
                    Type
                  </TableCell>
                  <TableCell scope="col" border="bottom">
                    Example
                  </TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell scope="row">
                    <Code>code</Code>
                  </TableCell>
                  <TableCell scope="row">
                    <Code>string (required)</Code>
                  </TableCell>
                  <TableCell scope="row">
                    <CodeBlock>console.log(%22hello%20world%22)</CodeBlock>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">
                    <Code>language</Code>
                  </TableCell>
                  <TableCell scope="row">
                    <Link to="/docs/supported-languages">
                      <Code>Supported Language</Code>
                    </Link>
                  </TableCell>
                  <TableCell scope="row">
                    <Code>js</Code>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">
                    <Code>theme</Code>
                  </TableCell>
                  <TableCell scope="row">
                    <Link to="/docs/supported-themes">
                      <Code>Supported Theme</Code>
                    </Link>
                  </TableCell>
                  <TableCell scope="row">
                    <CodeBlock>dark-plus</CodeBlock>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">
                    <Code>tabWidth</Code>
                  </TableCell>
                  <TableCell scope="row">
                    <Code>Positive integer</Code>
                  </TableCell>
                  <TableCell scope="row">
                    <CodeBlock>2</CodeBlock>
                  </TableCell>
                </TableRow>{' '}
              </TableBody>
            </Table>
            <Heading level={5}>Example</Heading>
            <CodeBlock>
              https://sourcecodeshots.com/api/image?language=js&theme=dark-plus&code=console.log(%22hello%20world%22)
            </CodeBlock>
            <CodeBlock>
              {`<img src="https://sourcecodeshots.com/api/image?language=js&theme=dark-plus&code=console.log(%22hello%20world%22)" />`}
            </CodeBlock>
            <P>
              <Image
                style={{maxWidth: '100%'}}
                fit="contain"
                fill={false}
                src="https://sourcecodeshots.com/api/image?language=js&theme=dark-plus&code=console.log(%22hello%20world%22)"
              />
            </P>
            <Heading level={3}>POST Request</Heading>
            <P>
              If you have a larger code snippet, you can use a <Code>POST</Code>{' '}
              request to get image data.
            </P>
            <P>
              Post requests accept an object with code and settings as JSON and
              return an image.
            </P>
            <Heading level={5}>Path</Heading>
            <Code>/api/image</Code>
            <Heading level={5}>Headers</Heading>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableCell scope="col" border="bottom">
                    Header
                  </TableCell>
                  <TableCell scope="col" border="bottom">
                    Value
                  </TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell scope="row">
                    <Code>Content-Type</Code>
                  </TableCell>
                  <TableCell scope="row">
                    <Code>application/json</Code>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Heading level={5}>Body</Heading>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableCell scope="col" border="bottom">
                    Type
                  </TableCell>
                  <TableCell scope="col" border="bottom">
                    Example
                  </TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell scope="row">
                    <SettingsType />
                  </TableCell>
                  <TableCell scope="row">
                    <CodeBlock>{settingsValue}</CodeBlock>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Heading level={5}>Example</Heading>
            <CodeBlock>{postExample}</CodeBlock>
            <Heading level={3}>Get a permanent URL</Heading>
            <P>
              You can use the same request body as the POST example above to get
              a permanent URL for your image. You can share the permanent url on
              social media, put it in an <Code>img</Code> on a blog, add it to a
              GitHub issue, etc.
            </P>
            <P>
              The request accepts an object with code and settings as JSON and
              returns JSON with a `url` key.
            </P>
            <Heading level={5}>Path</Heading>
            <Code>/api/image/permalink</Code>
            <Heading level={5}>Headers</Heading>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableCell scope="col" border="bottom">
                    Header
                  </TableCell>
                  <TableCell scope="col" border="bottom">
                    Value
                  </TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell scope="row">
                    <Code>Content-Type</Code>
                  </TableCell>
                  <TableCell scope="row">
                    <Code>application/json</Code>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Heading level={5}>Body</Heading>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableCell scope="col" border="bottom">
                    Type
                  </TableCell>
                  <TableCell scope="col" border="bottom">
                    Example
                  </TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell scope="row">
                    <SettingsType />
                  </TableCell>
                  <TableCell scope="row">
                    <CodeBlock>{settingsValue}</CodeBlock>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Heading level={5}>Response Body</Heading>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableCell scope="col" border="bottom">
                    Type
                  </TableCell>
                  <TableCell scope="col" border="bottom">
                    Example
                  </TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell scope="row">
                    <CodeBlock>{imageIdResponseBodyType}</CodeBlock>
                  </TableCell>
                  <TableCell style={{maxWidth: 400}} scope="row">
                    <CodeBlock>{imageIdResponseBodyExample}</CodeBlock>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Heading level={5}>Example</Heading>
            <CodeBlock>{imageIdExample}</CodeBlock>

            <Heading level={3} ref={highlightCodeRef} id="highlight">
              <Link to="/docs/highlight">#</Link> Highlight code
            </Heading>
            <P>
              You can also use the API to highlight code using the token info
              API. Submit code to the API and it will return a list of tokens
              with styling information.
            </P>
            <Heading level={5}>Path</Heading>
            <Code>/api/token-info</Code>
            <Heading level={5}>Headers</Heading>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableCell scope="col" border="bottom">
                    Header
                  </TableCell>
                  <TableCell scope="col" border="bottom">
                    Value
                  </TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell scope="row">
                    <Code>Content-Type</Code>
                  </TableCell>
                  <TableCell scope="row">
                    <Code>application/json</Code>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Heading level={5}>Body</Heading>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableCell scope="col" border="bottom">
                    Type
                  </TableCell>
                  <TableCell scope="col" border="bottom">
                    Example
                  </TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell scope="row">
                    <SettingsType />
                  </TableCell>
                  <TableCell scope="row">
                    <CodeBlock>{settingsValue}</CodeBlock>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Heading level={5}>Response Body</Heading>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableCell scope="col" border="bottom">
                    Type
                  </TableCell>
                  <TableCell scope="col" border="bottom">
                    Example
                  </TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow style={{verticalAlign: 'top'}}>
                  <TableCell scope="row">
                    <CodeBlock>{tokenInfoResponseBodyType}</CodeBlock>
                  </TableCell>
                  <TableCell style={{maxWidth: 400}} scope="row">
                    <CodeBlock>{tokenInfoResponseBodyExample}</CodeBlock>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Heading level={5}>Example</Heading>
            <HighlightCodeBlock code={hightlightingExample} />

            <Heading ref={supportedThemesRef} id="supported-themes" level={3}>
              Supported Themes
            </Heading>
            <P>
              These are all of the themes that the API supports. Use the value
              in the <Code>Id</Code> column when sending a request to the API.
            </P>
            <P>
              <a href="mailto:dwwoelfel@gmail.com?subject=Custom theme">
                Contact me
              </a>{' '}
              if you would like a theme not listed here.
            </P>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableCell scope="col" border="bottom">
                    Name
                  </TableCell>
                  <TableCell scope="col" border="bottom">
                    <Code>Id</Code>
                  </TableCell>
                  <TableCell scope="col" border="bottom">
                    Example
                  </TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {Object.keys(themes).map((k) => {
                  const {setting, label} = themes[k];

                  return (
                    <TableRow key={setting}>
                      <TableCell scope="row">{label}</TableCell>
                      <TableCell scope="row">
                        <Code>{setting}</Code>
                      </TableCell>
                      <TableCell scope="row">
                        <Button
                          style={{height: 40, width: 40}}
                          plain={true}
                          onClick={() => setLightboxOption(themes[k])}
                          label={
                            <Box height="40px" width="40px">
                              <Image
                                loading="lazy"
                                fit="contain"
                                fill={false}
                                src={defaultSrc(themes[k])}
                              />
                            </Box>
                          }
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Heading
              ref={supportedLanguagesRef}
              id="supported-languages"
              level={3}>
              Supported Languages
            </Heading>
            <P>
              These are all of the languages that the API supports. Use the
              value in the <Code>Id</Code> column when sending a request to the
              API.
            </P>
            <P>
              <a href="mailto:dwwoelfel@gmail.com?subject=New language">
                Contact me
              </a>{' '}
              if you would like a language not listed here.
            </P>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableCell scope="col" border="bottom">
                    Name
                  </TableCell>
                  <TableCell scope="col" border="bottom">
                    <Code>Id</Code>
                  </TableCell>
                  <TableCell scope="col" border="bottom">
                    Example
                  </TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {Object.keys(languages).map((k) => {
                  const {name, setting} = languages[k];
                  return (
                    <TableRow key={setting}>
                      <TableCell scope="row">{name}</TableCell>
                      <TableCell scope="row">
                        <Code>{setting}</Code>
                      </TableCell>
                      <TableCell scope="row">
                        <Button
                          style={{height: 40, width: 40}}
                          plain={true}
                          onClick={() => setLightboxOption(languages[k])}
                          label={
                            <Box height="40px" width="40px">
                              <Image
                                loading="lazy"
                                fit="contain"
                                fill={false}
                                src={defaultSrc(languages[k])}
                              />
                            </Box>
                          }
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </Box>
        {lightboxOption ? (
          <Box style={{width: '100vw', height: '100vh'}}>
            <Lightbox
              images={imageUrls}
              startIndex={imageOptionIdxMap.get(lightboxOption)}
              onClose={() => setLightboxOption(null)}
              allowZoom={false}
              allowRotate={false}
              allowReset={false}
            />
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default Docs;
