import App from './App';
import {BrowserRouter} from 'react-router-dom';
import React from 'react';
import {hydrate} from 'react-dom';

hydrate(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root'),
);

// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept();
}
