import React from 'react';
import {Route, Switch, Redirect, NavLink} from 'react-router-dom';
import Home from './Home';
import Docs from './Docs';
import {Grommet, Box, Heading} from 'grommet';
// @ts-ignore
import {dxc} from 'grommet-theme-dxc';
import {Helmet} from 'react-helmet';
// @ts-ignore
import {deepMerge} from 'grommet/utils/object';
// @ts-ignore
import {createGlobalStyle} from 'styled-components';
// @ts-ignore
import styled from 'styled-components';

const theme = deepMerge(dxc, {});

const GlobalStyle = createGlobalStyle`
body {
  margin: 0;
  padding: 0;
  font-family: Helvetica, sans-serif;
}`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const SubHead = styled(Heading)`
  @media (max-width: 800px) {
    display: none;
  }
`;

const App = () => (
  <Grommet full={false} theme={theme}>
    <GlobalStyle />
    <Helmet>
      <html lang="en" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta charSet="utf-8" />
      <title>Source Code Shots</title>
      <meta
        name="description"
        content="API to generate beautiful images of your source code."
      />
      <meta property="og:title" content="Source code shots" />
      <meta
        property="og:description"
        content="API to generate images from source code."
      />
      <meta name="theme-color" content="#000000" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </Helmet>
    <Main>
      <Box
        pad="small"
        align="center"
        width="100vw"
        style={{
          backgroundColor: '#1E1E1E',
          color: 'white',
        }}>
        <Box align="start" width="large" margin={{vertical: 'small'}}>
          <Box
            fill
            direction="row"
            justify="between"
            alignContent="between"
            align="stretch">
            <Box>
              <NavLink
                to="/"
                style={{color: 'inherit', textDecoration: 'none'}}>
                <Heading margin="none" level={2}>
                  Source Code Shots
                </Heading>
              </NavLink>
              <SubHead
                margin="none"
                style={{width: '100%', maxWidth: 'none'}}
                level={4}>
                API to generate beautiful images of your source code.
              </SubHead>
            </Box>
            <Box alignSelf="end">
              <NavLink
                style={{
                  color: 'inherit',
                  textDecoration: 'none',
                }}
                to="/docs"
                activeStyle={{display: 'none'}}>
                Docs
              </NavLink>
            </Box>
          </Box>
        </Box>
      </Box>

      <Switch>
        <Route
          exact
          path="/language/:languageSetting/theme/:themeSetting"
          component={Home}
        />
        <Route exact path="/docs/:hash?" component={Docs} />
        <Redirect
          path="/language/:language"
          to="/language/:language/theme/dark-plus"
        />
        <Route exact path="/" component={Home} />
      </Switch>
      {
        // footer
        // <Box
        //   fill={true}
        //   alignSelf="end"
        //   alignContent="end"
        //   direction="row"
        //   justify="center"
        //   flex="grow"
        //   align="end"
        //   pad="small">
        //   <NavLink activeStyle={{display: 'none'}} to="/docs">
        //     Docs
        //   </NavLink>
        // </Box>
      }
    </Main>
  </Grommet>
);

export default App;
