import type {Canvas} from 'canvas';

const titleBarHeight = 53;

type Tokens = Array<
  Array<{
    text: string;
    foregroundColor: string;
    backgroundColor: string;
    fontStyle: number;
    isItalic: boolean;
    isBold: boolean;
    isUnderlined: boolean;
  }>
>;

export type TokenInfo = {
  tokens: Tokens;
  maxLineLength: number;
  backgroundColor: string;
  foregroundColor: string;
  backdropColor: string;
  windowTheme: 'light' | 'dark';
};

export const FontStyle = {
  NotSet: -1,
  None: 0,
  Italic: 1,
  Bold: 2,
  Underline: 4,
};

function drawCode({
  ctx,
  startX,
  startY,
  tokens,
  lineHeight,
  maxWidthOnly,
}: {
  ctx: CanvasRenderingContext2D;
  startX: number;
  startY: number;
  tokens: Tokens;
  lineHeight: number;
  charWidth: number;
  maxWidthOnly: boolean;
}) {
  const offsetHeight = startY;
  let offsetWidth = startX;
  let maxWidth = offsetWidth;
  const baseFont = ctx.font;
  for (let lineNum = 0; lineNum < tokens.length; lineNum++) {
    const lineTokens = tokens[lineNum];
    offsetWidth = startX;
    for (let j = 0; j < lineTokens.length; j++) {
      const lineToken = lineTokens[j];
      const tokenText = lineToken.text;
      const foregroundColor = lineToken.foregroundColor;
      const fontStyle = lineToken.fontStyle;
      let font = baseFont;

      if (fontStyle > 0) {
        if ((fontStyle | FontStyle.Italic) === fontStyle) {
          font = `italic ${font}`;
        }
        if ((fontStyle | FontStyle.Bold) === fontStyle) {
          font = `bold ${font}`;
        }
      }
      ctx.font = font;
      ctx.fillStyle = foregroundColor;
      if (!maxWidthOnly) {
        ctx.fillText(
          tokenText,
          offsetWidth,
          offsetHeight + lineNum * lineHeight,
        );
      }
      offsetWidth += Math.ceil(ctx.measureText(tokenText).width);
    }
    maxWidth = Math.max(offsetWidth, maxWidth);
  }
  return {maxWidth};
}

type Radius = {
  tl: number;
  tr: number;
  bl: number;
  br: number;
};

function roundRect(
  ctx: CanvasRenderingContext2D,
  {
    x,
    y,
    width,
    height,
    radius,
  }: {
    x: number;
    y: number;
    width: number;
    height: number;
    radius: Radius;
  },
) {
  ctx.beginPath();
  ctx.moveTo(x + radius.tl, y);
  ctx.lineTo(x + width - radius.tr, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
  ctx.lineTo(x + width, y + height - radius.br);
  ctx.quadraticCurveTo(
    x + width,
    y + height,
    x + width - radius.br,
    y + height,
  );
  ctx.lineTo(x + radius.bl, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
  ctx.lineTo(x, y + radius.tl);
  ctx.quadraticCurveTo(x, y, x + radius.tl, y);
  ctx.closePath();

  ctx.fill();
}

type Window = {
  x: number;
  y: number;
  width: number;
  height: number;
  radius: Radius;
  theme: 'light' | 'dark';
};

function drawTitlebar({
  ctx,
  window,
}: {
  ctx: CanvasRenderingContext2D;
  window: Window;
}) {
  ctx.save();
  ctx.fillStyle = window.theme === 'dark' ? '#191C1F' : '#ffffff';
  ctx.shadowColor = 'rgba(0, 0, 0, 0.15)';
  ctx.shadowBlur = 1;
  ctx.shadowOffsetY = -0.5; //inset 0px -0.5px 0px rgba(0, 0, 0, 0.05)
  //0px 0.5px 0px rgba(0, 0, 0, 0.15), inset 0px -0.5px 0px rgba(0, 0, 0, 0.05);
  roundRect(ctx, {
    x: window.x,
    y: window.y,
    width: window.width,
    height: titleBarHeight,
    radius: {tl: 10, tr: 10, bl: 0, br: 0},
  });
  ctx.restore();
  ctx.save();
  ctx.strokeStyle = 'rgba(0, 0, 0, 0.15)';
  ctx.beginPath();
  ctx.moveTo(window.x, window.y + titleBarHeight - 0.5);
  ctx.lineTo(window.x + window.width, window.y + titleBarHeight - 0.5);
  ctx.lineWidth = 0.5;
  ctx.stroke();
  ctx.restore();

  const buttonHeight = 20;
  const buttonRadius = Math.ceil(buttonHeight / 2);
  const buttonSpacing = 12;
  const y =
    window.y + Math.round((titleBarHeight - buttonHeight) / 2) + buttonRadius;
  const buttonOffset = window.x + 31;

  // close button
  ctx.fillStyle = '#EE6A5F';
  ctx.beginPath();
  ctx.arc(buttonOffset, y, buttonRadius, 0, 2 * Math.PI);
  ctx.fill();

  // minimize button
  ctx.fillStyle = '#F5BD4F';
  ctx.beginPath();
  ctx.arc(
    buttonOffset + buttonHeight + buttonSpacing,
    y,
    buttonRadius,
    0,
    2 * Math.PI,
  );
  ctx.fill();

  // fullscreen
  ctx.fillStyle = '#61C454';
  ctx.beginPath();
  ctx.arc(
    buttonOffset + (buttonHeight + buttonSpacing) * 2,
    y,
    buttonRadius,
    0,
    2 * Math.PI,
  );
  ctx.fill();
}

export function drawImage(
  canvas: HTMLCanvasElement | Canvas,
  tokenInfo: TokenInfo,
) {
  // Settings
  // TODO: take as settings
  const fontSize = 28;
  const windowMargin = 64;
  const windowPadding = 32;
  const windowRadius = {tl: 15, tr: 15, bl: 5, br: 5};

  const lineHeight = Math.floor(fontSize * 1.4);

  // @ts-ignore
  const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');

  if (!ctx) {
    console.error('Did not get a context');
    return;
  }

  ctx.font = `${fontSize}px Hack`;

  const charMeasure = ctx.measureText('a');
  const charWidth = Math.ceil(charMeasure.width);
  const {maxWidth} = drawCode({
    ctx,
    startX: 0,
    startY: 0,
    tokens: tokenInfo.tokens,
    lineHeight,
    charWidth,
    maxWidthOnly: true,
  });

  const codeWidth = Math.min(
    Math.max(charWidth * 30, maxWidth),
    charWidth * 100,
  );

  const codeHeight = tokenInfo.tokens.length * lineHeight;

  const window = {
    x: windowMargin,
    y: windowMargin,
    width: codeWidth + windowPadding * 2,
    height: codeHeight + windowPadding * 2 + titleBarHeight,
    radius: windowRadius,
    theme: tokenInfo.windowTheme,
  };

  const canvasWidth = window.width + windowMargin * 2;
  const canvasHeight = window.height + windowMargin * 2;

  canvas.width = canvasWidth;
  canvas.height = canvasHeight;
  ctx.font = `${fontSize}px Hack`;

  ctx.save();
  ctx.fillStyle = tokenInfo.backdropColor;
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.restore();

  ctx.save();
  ctx.fillStyle = tokenInfo.backgroundColor;

  ctx.shadowColor = 'rgba(0, 0, 0, 0.7)';
  ctx.shadowBlur = 1;
  ctx.shadowOffsetX = 0;
  roundRect(ctx, window);

  ctx.shadowColor = 'rgba(0, 0, 0, 0.3)';
  ctx.shadowBlur = 30;
  ctx.shadowOffsetX = 20;
  roundRect(ctx, window);

  ctx.shadowColor = 'rgba(0, 0, 0, 0.2)';
  ctx.shadowBlur = 50;
  ctx.shadowOffsetX = 10;
  roundRect(ctx, window);
  ctx.restore();

  ctx.save();
  const codeStartX = window.x + windowPadding;
  const codeStartY = window.y + windowPadding + titleBarHeight;

  ctx.beginPath();
  ctx.rect(codeStartX, codeStartY, codeWidth, codeHeight);
  ctx.clip();
  drawCode({
    ctx,
    startX: codeStartX,
    startY: codeStartY + fontSize,
    tokens: tokenInfo.tokens,
    lineHeight,
    charWidth,
    maxWidthOnly: false,
  });
  ctx.restore();

  drawTitlebar({
    ctx,
    window,
  });
}
