import React from 'react';
import {
  Box,
  Image,
  TextInput,
  Button,
  DropButton,
  Select,
  TextArea,
  ResponsiveContext,
} from 'grommet';
import {SettingsOption} from 'grommet-icons';
import {drawImage} from './drawImage';
import loadClientFonts from './loadClientFonts';
import {languages, themes} from './settings';
import deepEqual from 'deep-equal';
import {Redirect, RouteComponentProps, useHistory} from 'react-router-dom';
import {Helmet} from 'react-helmet';
// @ts-ignore
import {keyframes} from 'styled-components';
// @ts-ignore
import styled from 'styled-components';

import type {FunctionComponent} from 'react';
import type {Language, Theme} from './settings';

const pulsate = keyframes`
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
`;

const Loading = styled.div`
  animation: ${pulsate} 1s ease-out;
  animation-iteration-count: 0;
  opacity: 0;

  border: 3px solid #999;
  border-radius: 30px;
  height: 16px;
  width: 16px;
  position: absolute;
  display: inline-block;
  text-align: center;
  margin: 8px;
  top: 0;
  left: 0;
  pointer-events: none;

  @media (max-width: 800px) {
    width: 8px;
    height: 8px;
    margin: 4px;
  }
`;

const ButtonLoading = styled.div`
  animation: ${pulsate} 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  border: 3px solid #999;
  border-radius: 30px;
  height: 16px;
  width: 16px;
  position: absolute;
  display: inline-block;
  text-align: center;
  margin: 0;
  top: calc(50% - 8px);
  left: 12px;
  pointer-events: none;
  box-sizing: border-box;

  @media (max-width: 800px) {
    width: 14px;
    height: 14px;
    top: calc(50% - 7px);
    left: 7px;
  }
`;

type Settings = {
  language: string;
  theme: string;
  tabWidth: number;
};

function encodeParams(params: Array<{key: string; value: string}>): string {
  return params
    .map(({key, value}) => `${key}=${encodeURIComponent(value)}`)
    .join('&');
}

function makeTokenInfoGetUrl({
  code,
  settings,
}: {
  code: string;
  settings: Settings;
}): string | null {
  try {
    const params = [
      {key: 'code', value: code},
      {key: 'language', value: settings.language},
      {key: 'theme', value: settings.theme},
      {key: 'tabWidth', value: String(settings.tabWidth)},
    ];
    return `/api/token-info?${encodeParams(params)}`;
  } catch (e) {
    return null;
  }
}

const Home: FunctionComponent<{language: Language; theme: Theme}> = ({
  language,
  theme,
}) => {
  const [tabWidth, setTabWidth] = React.useState(2);
  const settings = React.useMemo(
    () => ({language: language.setting, theme: theme.setting, tabWidth}),
    [language, theme, tabWidth],
  );

  const history = useHistory();

  const size = React.useContext(ResponsiveContext);

  const canvasRef = React.useRef(
    typeof window !== 'undefined' ? document.createElement('canvas') : null,
  );

  const [languageOptions, setLanguageOptions] = React.useState(
    Object.values(languages),
  );

  const [themeOptions, setThemeOptions] = React.useState(Object.values(themes));

  // TODO: don't overwrite
  const [code, setCode] = React.useState(language.snippet);

  const [imageUrl, setImageUrl] = React.useState(
    `/image/default/${settings.language}/${settings.theme}.png`,
  );

  const [imageLoading, setImageLoading] = React.useState(false);
  const [loadingPermalink, setLoadingPermalink] = React.useState(false);

  const [imageId, setImageId] = React.useState<{
    id: string;
    code: string;
    settings: Settings;
  } | null>(null);

  const inputRef = React.useRef<HTMLInputElement>(null);
  const selectedInput = React.useRef(false);
  const loadingRef = React.useRef<HTMLDivElement>(null);
  const [loadingKey, setLoadingKey] = React.useState(0);
  const nextLoadingKey = React.useRef(1);
  const [ic, setIc] = React.useState(0);

  const imageIsPermalink =
    imageId &&
    imageId.code === code &&
    deepEqual(imageId.settings, settings, {strict: true});

  React.useEffect(() => {
    const el = loadingRef.current;
    if (imageLoading && el) {
      if (loadingKey !== nextLoadingKey.current) {
        setIc(1);
        setLoadingKey(nextLoadingKey.current);
      }
    }
  }, [imageLoading, loadingKey, setIc]);

  React.useEffect(() => {
    let canceled = false;
    const abortController = new AbortController();
    setImageLoading(true);
    const getUrl = makeTokenInfoGetUrl({code, settings});

    Promise.all([
      getUrl && getUrl.length < 2083
        ? fetch(getUrl, {signal: abortController.signal})
        : fetch('/api/token-info', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
              code,
              settings: settings,
            }),
            signal: abortController.signal,
          }),
      loadClientFonts(),
    ])
      .then(([res]) => {
        return res.json();
      })
      .then((tokenInfo) => {
        const canvas = canvasRef.current;
        if (!canceled && canvas) {
          drawImage(canvas, tokenInfo);
          const dataUrl = canvas.toDataURL();
          setImageUrl(dataUrl);
          setImageLoading(false);
        }
      })
      .catch((e) => {
        if (!canceled) {
          setImageLoading(false);
          console.error('Error loading image', e);
        }
      });

    return () => {
      canceled = true;
      abortController.abort();
    };
  }, [code, settings, setImageUrl]);

  React.useEffect(() => {
    if (
      !selectedInput.current &&
      imageId &&
      imageId.code === code &&
      imageId.settings === settings &&
      inputRef.current
    ) {
      selectedInput.current = true;
      inputRef.current.select();
    }
  }, [imageId, code, settings]);

  const getImageId = React.useCallback(() => {
    const currentCode = code;
    setLoadingPermalink(true);
    fetch('/api/image/permalink', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        code,
        settings: settings,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setLoadingPermalink(false);
        selectedInput.current = false;
        setImageId({
          id: res.id,
          code: currentCode,
          settings: settings,
        });
      })
      .catch((e) => {
        console.error('error creating permalink', e);
        alert('There was an error creating the permalink. Please try again.');
        setLoadingPermalink(false);
      });
  }, [code, setLoadingPermalink, setImageId, settings]);

  const title = `Source Code Shots - ${language.name} with ${theme.label} theme`;
  const description = `API to generate beautiful images of your ${language.name}.`;
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content={`https://sourcecodeshots.com/image/default/${
            settings.language
          }/${encodeURIComponent(settings.theme)}.png`}
        />
      </Helmet>
      <Box align="center" pad="small">
        <Box width="large" margin={{top: 'large', bottom: 'large'}}>
          <Box
            height="25vh"
            margin={{
              //top: 'large',
              bottom: 'small',
            }}
            width="large"
            round="4px"
            border={{color: 'rgba(0,0,0,0.33)', size: 'small'}}>
            <TextArea
              autoCapitalize="none"
              autoComplete="off"
              spellCheck={false}
              style={{
                fontSize: 14,
                fontFamily: 'Hack, monospace',
                whiteSpace: 'pre',
              }}
              value={code}
              resize={false}
              onChange={(e) => setCode(e.target.value || '')}
              fill={true}
              focusIndicator={false}
              plain={true}
            />
          </Box>
          <Box direction="row" width="large">
            <Box pad={{right: 'xsmall', vertical: 'xsmall'}} justify="center">
              <DropButton
                dropAlign={{top: 'bottom', left: 'left'}}
                dropContent={
                  <Box pad="medium">
                    <Box align="center" gap="small" direction="row">
                      <span>Tab Width</span>
                      <Box width="xsmall">
                        <Select
                          dropAlign={
                            size === 'small'
                              ? {bottom: 'top'}
                              : {top: 'bottom', left: 'left'}
                          }
                          options={['1', '2', '3', '4', '5', '6', '7', '8']}
                          value={String(tabWidth)}
                          onChange={({value}) => {
                            setTabWidth(parseInt(value, 10));
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                }>
                <Box>
                  <SettingsOption />
                </Box>
              </DropButton>
            </Box>
            <Box basis="1/3">
              <Select
                dropAlign={
                  size === 'small'
                    ? {bottom: 'top'}
                    : {top: 'bottom', left: 'left'}
                }
                options={languageOptions}
                labelKey="name"
                value={language}
                onChange={({value}) => {
                  if (code === language.snippet) {
                    setCode(value.snippet);
                  }
                  history.replace(
                    `/language/${value.setting}/theme/${settings.theme}`,
                  );
                }}
                onClose={() => setLanguageOptions(Object.values(languages))}
                onSearch={(text) => {
                  // TODO: also search alternate names
                  // Escape regex special chars [ \ ^ $ . | ? * + ( )
                  const escapedText = text.replace(
                    /[-\\^$*+?.()|[\]{}]/g,
                    '\\$&',
                  );
                  const exp = new RegExp(escapedText, 'i');
                  setLanguageOptions(
                    Object.values(languages).filter(
                      (o) => exp.test(o.name) || exp.test(o.setting),
                    ),
                  );
                }}
              />
            </Box>
            <Box basis="1/3">
              <Select
                dropAlign={
                  size === 'small'
                    ? {bottom: 'top'}
                    : {top: 'bottom', left: 'left'}
                }
                options={themeOptions}
                labelKey="label"
                value={theme}
                onChange={({value}) => {
                  history.replace(
                    `/language/${settings.language}/theme/${value.setting}`,
                  );
                }}
                onClose={() => setThemeOptions(Object.values(themes))}
                onSearch={(text) => {
                  // Escape regex special chars [ \ ^ $ . | ? * + ( )
                  const escapedText = text.replace(
                    /[-\\^$*+?.()|[\]{}]/g,
                    '\\$&',
                  );
                  const exp = new RegExp(escapedText, 'i');
                  setThemeOptions(
                    Object.values(themes).filter(
                      (o) => exp.test(o.label) || exp.test(o.id),
                    ),
                  );
                }}
              />
            </Box>
            <Box style={{position: 'relative'}} basis="1/3">
              <Button
                style={{
                  border: '1px solid rgba(0,0,0,0.33)',
                  borderRadius: 4,
                  height: '100%',
                  overflow: 'hidden',
                }}
                size="medium"
                disabled={loadingPermalink || imageIsPermalink ? true : false}
                label={
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}>
                    {size === 'small' ? 'Get URL' : 'Get permanent URL'}
                  </span>
                }
                onClick={getImageId}
              />
              {loadingPermalink ? <ButtonLoading /> : null}
            </Box>
          </Box>
          <Box
            margin={{bottom: 'small'}}
            width="large"
            height={imageId ? undefined : '0'}>
            <Box
              margin={{top: 'small'}}
              style={{width: '100%', display: imageId ? 'block' : 'none'}}>
              <TextInput
                // @ts-ignore
                ref={inputRef}
                placeholder="Permanent URL"
                value={
                  imageId
                    ? `https://sourcecodeshots.com/image/${imageId.id}.png`
                    : ''
                }
                style={{width: '100%'}}
                type="text"
                size="small"
                disabled={!imageId || !imageIsPermalink}
              />
            </Box>
          </Box>

          <Box width="large" style={{position: 'relative'}}>
            <Image
              style={{width: '100%'}}
              alignSelf="start"
              fit="contain"
              fill={false}
              src={
                imageId && imageIsPermalink
                  ? `/image/${imageId.id}.png`
                  : imageUrl
              }
            />
            <Loading
              key={loadingKey}
              ref={loadingRef}
              style={{animationIterationCount: ic}}
              onAnimationEnd={() => {
                const el = loadingRef.current;
                if (el) {
                  if (imageLoading) {
                    setIc(ic + 1);
                  } else {
                    nextLoadingKey.current += 1;
                  }
                }
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

const HomeWrapper: FunctionComponent<RouteComponentProps<{
  languageSetting: string;
  themeSetting: string;
}>> = (props) => {  
  const {languageSetting, themeSetting} = props.match.params;

  const language = languages[languageSetting || 'js'];
  const theme = themes[themeSetting || 'dark-plus'];

  if (!language || !theme) {
    return <Redirect to="/language/js/theme/dark-plus" />;
  }
  return <Home language={language} theme={theme} />;
};

export default HomeWrapper;
