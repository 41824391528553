function fontFile(name: string) {
  return `/static/fonts/${name}`;
}

async function registerFont(
  url: string,
  {
    family,
    weight,
    style,
  }: {
    family: string;
    weight?: 'bold' | undefined;
    style?: 'italic' | undefined;
  },
) {
  // @ts-ignore
  const f = new FontFace(family, `url(${url})`);
  if (weight) {
    f.weight = weight;
  }
  if (style) {
    f.style = style;
  }
  const font = await f.load();
  // @ts-ignore
  document.fonts.add(font);
}

async function loadFontsImpl() {
  await Promise.all([
    registerFont(fontFile('Hack-Regular.ttf'), {family: 'Hack'}),
    registerFont(fontFile('Hack-Bold.ttf'), {family: 'Hack', weight: 'bold'}),
    registerFont(fontFile('Hack-Italic.ttf'), {
      family: 'Hack',
      style: 'italic',
    }),
    registerFont(fontFile('Hack-BoldItalic.ttf'), {
      family: 'Hack',
      weight: 'bold',
      style: 'italic',
    }),
  ]);
}

let fontsLoadedPromise: Promise<any> | undefined | null;

export default function loadFonts() {
  if (fontsLoadedPromise) {
    return fontsLoadedPromise;
  }
  fontsLoadedPromise = loadFontsImpl();
  fontsLoadedPromise.catch((e: Error) => {
    console.error('Error loading fonts', e);
    fontsLoadedPromise = null;
  });
  return fontsLoadedPromise;
}
